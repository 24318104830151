@tailwind base;
@tailwind components;
@tailwind utilities;

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.footer-box {
  filter: drop-shadow(0px 0px 10px #0000001a);
}

.dash-card {
  background-image: linear-gradient(319deg, #63b509, #b6e683);
}

.info-card1 {
  background-image: linear-gradient(301deg, #096531, #00a146);
}

.info-card2 {
  background-image: linear-gradient(121deg, #8dc63f, #579700);
}

.fav-card {
  background-image: linear-gradient(296deg, #fbccab, #fce2b7);
}

@font-face {
  font-family: SF-Pro-Display-Regular;
  src: url("./fonts/SF-Pro-Display-Regular.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-Medium;
  src: url("./fonts/SF-Pro-Display-Medium.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-Semibold;
  src: url("./fonts/SF-Pro-Display-Semibold.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-Bold;
  src: url("./fonts/SF-Pro-Display-Bold.otf") format("opentype");
}
